<template>
    <div>
        <nav-bar />
        <div class="main">
            <banner />
            <match />
            <site-footer />
        </div>
    </div>
</template>

<script>
import NavBar from "../views/commons/NavBar";
import Banner from "../views/matchPage/Banner";
import match from "../views/matchPage/match";
import SiteFooter from "../views/index-five/SiteFooter";

export default {
    name: "MatchPage",
    components: {
        NavBar,
        Banner,
        match,
        SiteFooter,
    },
};
</script>

