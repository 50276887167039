<template>
    <div>
        <nav-bar />
        <div class="main">
            <section
        class="hero-section ptb-100 background-img"
        style="
            background: url('img/hero.jpg') no-repeat center center / cover;
        "
    >
        <div class="container">
            <div class="row align-items-center justify-content-center">
                <div class="col-md-9 col-lg-7">
                    <div
                        class="page-header-content text-white text-center "
                    >
                        <h1 class="text-white mb-0"> {{$t('relatedVideo')}}</h1>
                        <div class="custom-breadcrumb">
                            <ol
                                class="breadcrumb d-inline-block bg-transparent list-inline py-0"
                            >
                                <li class="list-inline-item breadcrumb-item">
                                    <router-link
                                class="nav-link page-scroll bold matchBtn "
                                to="/"
                                >{{$t('home')}}</router-link
                            >
                                </li>
                                <li
                                    class="list-inline-item breadcrumb-item active"
                                >
                                     {{$t('relatedVideo')}}
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
            <section class="gray-light-bg ptb-100">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-md-9 col-lg-8">
                    <h1>Page Under Construction</h1>
                </div>
            </div>
        </div>
            </section>
           
            <site-footer />
        </div>
    </div>
</template>

<script>
import NavBar from "../views/commons/NavBar";
import SiteFooter from "../views/index-five/SiteFooter";

export default {
    name: "confinementservice",
    components: {
        NavBar,
        SiteFooter,
    },
};
</script>

