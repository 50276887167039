<template>
    <section
        id="contact"
        class="contact-us ptb-100"
    >
        <div class="container">
            <div class="row">
                <div
                    class="col-12 pb-3 message-box d-done"
                >
                </div>
                <div class="col-md-5">
                    <div class="section-heading">
                        <h3>{{$t('ContactUs')}}</h3>
                        <p>
                          {{$t('contactuspage')}}
                        </p>
                    </div>
                    <div class="footer-address">
                        <h6><strong>{{$t('headoffice')}}</strong></h6>
                        <p>{{$t('address2')}} {{$t('address')}}</p>
                        <ul>
                            <li><span>{{$t('tel')}}: +852 25599223</span></li>
                            <li><span>{{$t('fax')}}: +852 25599700</span></li>
                            <li>
                                <span
                                    >{{$t('email')}} :
                                    <a href="mailto:cs@starjasmine.com.hk"
                                        >cs@starjasmine.com.hk</a
                                    ></span
                                >
                            </li>
                        </ul>
                    </div>
                        <div class="social-list-wrap">
                                <ul
                                    class="social-list list-inline list-unstyled"
                                >
                                    <li class="list-inline-item">
                                        <a
                                            href="https://wa.me/85251737224"
                                            target="_blank"
                                            title="WhatsApp"
                                            ><span class="ti-comment"></span
                                        ></a>
                                    </li>
                                    &nbsp;
                                    <li class="list-inline-item">
                                        <a
                                            href="https://www.facebook.com/%E6%98%9F%E7%A9%BA%E5%83%B1%E5%82%AD%E4%B8%AD%E5%BF%83-Star-Jasmine-Employment-Centre-102117558909715"
                                            target="_blank"
                                            title="Facebook"
                                            ><span class="ti-facebook"></span
                                        ></a>
                                    </li>
                                    &nbsp;

                                    <li class="list-inline-item">
                                        <a
                                            href="https://www.instagram.com/star_jasmine_employment_centre/"
                                            target="_blank"
                                            title="Instagram"
                                            ><span class="ti-instagram"></span
                                        ></a>
                                    </li>
                                    &nbsp;

                                </ul>
                            </div>
                </div>
                <div class="col-md-7">
                    <div class="map-container">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d230.70223768530886!2d114.1880394986012!3d22.306920125971676!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x340400de2501be6d%3A0xba9a2779dff22d46!2sWhampoa%20108!5e0!3m2!1sen!2sus!4v1715742327660!5m2!1sen!2sus" width="600" height="250" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>
                <div class="col-md-5">
                    <div class="section-heading">
                    
                   
                    </div>
                    <div class="footer-address">
                       
                        <p>{{$t('address4')}} {{$t('address3')}}</p>
                        <ul>
                            <li><span>{{$t('tel')}}: +852 9848 4244</span></li>
                            <li>
                                <span
                                    >{{$t('email')}} :
                                    <a href="mailto:cs@starjasmine.com.hk"
                                        >cs@starjasmine.com.hk</a
                                    ></span
                                >
                            </li>
                        </ul>
                    </div>
                        <div class="social-list-wrap">
                                <ul
                                    class="social-list list-inline list-unstyled"
                                >
                                    <li class="list-inline-item">
                                        <a
                                            href="https://wa.me/85251737224"
                                            target="_blank"
                                            title="WhatsApp"
                                            ><span class="ti-comment"></span
                                        ></a>
                                    </li>
                                    &nbsp;
                                    <li class="list-inline-item">
                                        <a
                                            href="https://www.facebook.com/%E6%98%9F%E7%A9%BA%E5%83%B1%E5%82%AD%E4%B8%AD%E5%BF%83-Star-Jasmine-Employment-Centre-102117558909715"
                                            target="_blank"
                                            title="Facebook"
                                            ><span class="ti-facebook"></span
                                        ></a>
                                    </li>
                                    &nbsp;

                                    <li class="list-inline-item">
                                        <a
                                            href="https://www.instagram.com/star_jasmine_employment_centre/"
                                            target="_blank"
                                            title="Instagram"
                                            ><span class="ti-instagram"></span
                                        ></a>
                                    </li>
                                    &nbsp;

                                </ul>
                            </div>
                </div>
                <div class="col-md-7">
                    <div class="map-container">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d230.70223768530886!2d114.1880394986012!3d22.306920125971676!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x340400de2501be6d%3A0xba9a2779dff22d46!2sWhampoa%20108!5e0!3m2!1sen!2sus!4v1715742327660!5m2!1sen!2sus" width="600" height="250" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "FaqOne",
    components: {
    
    },
    data: function () {
        return {
        };
    },
};
</script>

<style>
.map-container {
    position: relative;
    padding-bottom: 26.25%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;
}

.map-container iframe,
.map-container object,
.map-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
</style>