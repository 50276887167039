<template>
<v-app>
    <v-container>


      <matchComponent class="mb-10" />
      
  <!-- <v-stepper class='mt-10'
    v-model="wizard"
    vertical
  >
    <v-stepper-step
      step="1"
      :complete='wizard >= 1'
    >
    {{$t('wizardStep1')}}

    </v-stepper-step>

    <v-stepper-content step="1">
      <v-card
        class="mb-12"
      >
      <div class='ma-3'>
      <v-checkbox
      prepend-icon='mdi-baby-carriage'
      v-model="skillset"
      value="careInfant"
      :label="$t('careInfant')"
    >
    </v-checkbox>
    <v-checkbox
     value="careChild"
      v-model="skillset"
      prepend-icon='mdi-human-male-child'
      :label="$t('careChild')"
    ></v-checkbox>
    <v-checkbox
     value="careElderly"
      v-model="skillset"
      prepend-icon='mdi-human-cane'
      :label="$t('careElderly')"
    ></v-checkbox>
    <v-checkbox
     value="careDisabled"
      v-model="skillset"
      prepend-icon='mdi-human-wheelchair'
      :label="$t('careDisabled')"
    ></v-checkbox>
    <v-checkbox
     value="careBedridden"
      v-model="skillset"
      prepend-icon='mdi-emoticon-sick-outline'
      :label="$t('careBedridden')"
    ></v-checkbox>
    <v-checkbox
     value="carePet"
      v-model="skillset"
      prepend-icon='mdi-paw'
      :label="$t('carePet')"
    ></v-checkbox>
    <v-checkbox
     value="cooking"
      v-model="skillset"
       prepend-icon='mdi-chef-hat'
      :label="$t('cooking')"
    ></v-checkbox>
    <v-checkbox
     value="generalHousework"
      v-model="skillset"
       prepend-icon='mdi-spray-bottle'
      :label="$t('generalHousework')"
    ></v-checkbox>
        <v-checkbox
     value="carWashing"
      v-model="skillset"
       prepend-icon='mdi-car-wash'
      :label="$t('carWashing')"
    ></v-checkbox>
      </div>
      </v-card>
      <v-btn
        color="primary"
        @click="validateStepOne"
      >
        {{$t('continue')}}
      </v-btn>
    </v-stepper-content>

    <v-stepper-step
      step="2"
      :complete='wizard >= 2'
    >
      {{$t('wizardStep2')}}
    </v-stepper-step>

    <v-stepper-content step="2">
      <v-card
        class="mb-12"
      >
      <div class='ma-3'>
       <v-checkbox
      v-model="personality"
      :label="$t('cleanliness')"
      value="cleanliness"
    ></v-checkbox>
    <v-checkbox
      v-model="personality"
      :label="$t('discipline')"
      value="discipline"
    ></v-checkbox>
    <v-checkbox
      v-model="personality"
      :label="$t('empathy')"
      value="empathy"
    ></v-checkbox>
    <v-checkbox
      v-model="personality"
      :label="$t('efficiency')"
      value="efficiency"
    ></v-checkbox>
    <v-checkbox
      v-model="personality"
      :label="$t('friendliness')"
      value="friendliness"
    ></v-checkbox>
    <v-checkbox
      v-model="personality"
      :label="$t('hardworking')"
      value="hardworking"
    ></v-checkbox>
    <v-checkbox
      v-model="personality"
      :label="$t('honesty')"
      value="honesty"
    ></v-checkbox>
    <v-checkbox
      v-model="personality"
      :label="$t('patience')"
      value="patience"
    ></v-checkbox>
     <v-checkbox
      v-model="personality"
      :label="$t('proactiveness')"
      value="proactiveness"
    ></v-checkbox>
     <v-checkbox
      v-model="personality"
      :label="$t('responsible')"
      value="responsible"
    ></v-checkbox>
      </div>
      </v-card>
      <v-btn
        color="primary"
        @click="validateStepTwo"
      >
       {{$t('continue')}}
      </v-btn>
      <v-btn @click="wizard = 1"  text>
        {{$t('back')}}
      </v-btn>
    </v-stepper-content>


    <v-stepper-step :complete='wizard >= 3' step="3">
      {{$t('wizardStep3')}}
    </v-stepper-step>

    <v-stepper-content step="3">
      <v-card
        class="mb-12"
        elevation='0'
      >
      <div class='ma-3'>
        <v-form
        ref="form"
    v-model="valid"
    lazy-validation>
            <v-select
                 :items="['1-2','3-4','5+']"
                  :label="$t('familyMember')"
                  v-model="familyMember"
                  :rules="requiredRule"
                  required
                ></v-select>
                  <v-select
                  :items="$t('yesno')"
                   :label="$t('prevExp')"
                   :rules="requiredRule"
                  v-model="prevExp"
                  required
                ></v-select>
                  <v-select
                  :items="$t('yesno')"
                   :label="$t('privateRoom')"
                   :rules="requiredRule"
                  v-model="privateRoom"
                  required
                ></v-select>
                  <v-select
                  :items="$t('helperTypeValue')"
                   :label="$t('helperType')"
                   :rules="requiredRule"
                  v-model="helperType"
                  required
                ></v-select>
        </v-form>
      </div>
      </v-card>
      <v-card-actions>
        <v-btn
        :loading='btnloading'
        color="primary"
        @click="complete"
      >
        {{$t('complete')}}
      </v-btn>
      <v-btn @click="wizard = 2"  text>
        {{$t('back')}}
      </v-btn>
      </v-card-actions>
      
    </v-stepper-content>

        <v-stepper-step step="4">
      {{$t('wizardStep4')}}
    </v-stepper-step>

    <v-stepper-content step="4">
      <div v-if='apiresult.length === 0'>
        <p><img width='250px' src='../../assets/400.jpg' /></p>
        {{$t('noMatchResult')}}
        <ContactUs :matchID='matchid'/>
      </div>
       
      
      <v-card-actions>
        <v-btn color='warning' @click="reset"  text>
        {{$t('reset')}}
      </v-btn>
        <v-btn @click="wizard = 3"  text>
        {{$t('back')}}
      </v-btn>
      
      </v-card-actions>
      
    </v-stepper-content>
  </v-stepper>

   <MatchResult :skillset='skillset' :personality='personality' :matchid='matchid' :dataset='apiresult[0]' :allResult='allResult' />
     -->

  </v-container>
</v-app>
</template>

<script>
import axios from 'axios';
// import MatchResult from './MatchResult.vue';
// import ContactUs from './ContactUs.vue';
import matchComponent from './matchComponent.vue'
export default {
    components : {matchComponent},
    methods : {
              validateStepOne(){
            if(this.skillset.length > 0 ){
                 this.wizard = 2;
            }else{
                alert(this.$t('skillWarning'));
                return;
            }
           
        },
        validateStepTwo(){
            if(this.personality.length <= 5){
                 this.wizard = 3;
            }else{
                alert(this.$t('personalityWarning'));
                return;
            }
           
        },
        reset(){
          this.wizard = 1;
          this.apiresult = [];
          this.helperType = null;
          this.privateRoom = null;
          this.prevExp = null;
          this.familyMember = 0;
          this.skillset = [];
          this.personality= [];
        this.careInfant = null;
        this.careChild = 0;
        this.careElderly = 0;
        this.careDisabled= 0;
        this.careBedridden = 0;
        this.carePet = 0;
        this.cooking = 0;
        this.generalHousework = 0;
        this.carWashing = 0;
        },
        async complete(){
          let valid = this.$refs.form.validate();
            if(valid){
                        this.btnloading = true;
                let options = {
              'content-type': 'text/json',
              "Access-Control-Allow-Origin": "*"
              
            };
            this.apiresult = await axios.post('https://asia-east2-starjasmine-aacba.cloudfunctions.net/matching/intake',{
              skillset : this.skillset,
              personality : this.personality,
              familyMember : this.familyMember,
              helperType : this.helperType,
              privateRoom : this.privateRoom,
              prevExp : this.prevExp,
            },options);
            this.matchid = this.apiresult.data.matchResultId;
            this.allResult = this.apiresult.data.allResult;
            this.apiresult = this.apiresult.data.data;
            this.btnloading = false;
            this.wizard = 4;
        }
            }
            
    },
    name: "FaqTwo",
     data () {
      return {
        allResult : 0,
        btnloading : false,
        matchid : null,
        requiredRule : [ v => !!v || 'This Field is required'],
        apiresult : [],
        valid : true,
          helperType : null,
          privateRoom : null,
          prevExp : null,
          familyMember : 0,
          skillset : [],
          personality: [],
        wizard: 1,
        careInfant : null,
        careChild : 0,
        careElderly : 0,
        careDisabled: 0,
        careBedridden : 0,
        carePet : 0,
        cooking : 0,
        generalHousework : 0,
        carWashing : 0,
      }
    },
};
</script>

<style>
</style>