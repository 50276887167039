import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";

// ------------ css ----------
import "./assets/css/bootstrap.min.css";
import "./assets/css/themify-icons.css";
import "./assets/css/animate.min.css";
import "./assets/css/main.css";
import "./assets/css/responsive.css";

import VueScrollTo from "vue-scrollto";
import VueYoutube from "vue-youtube";
import { gsap } from "gsap";
import { CSSRulePlugin } from "gsap/CSSRulePlugin";

import jQuery from "jquery";
window.$ = jQuery;

import "jquery.mb.ytplayer";
import i18n from "@/plugins/i18n";
import VueFbCustomerChat from "vue-fb-customer-chat";
import vuetify from "./plugins/vuetify";
import axios from "axios";
import VueApexCharts from "vue-apexcharts";
Vue.use(VueApexCharts);

Vue.component("apexchart", VueApexCharts);

Vue.use(VueFbCustomerChat, {
	page_id: 102117558909715, //  change 'null' to your Facebook Page ID,
	theme_color: "#48a8b0", // theme color in HEX
	locale: "zh_HK", // default 'en_US'
});

Vue.use(VueScrollTo);
Vue.use(VueYoutube);

gsap.registerPlugin(CSSRulePlugin);

Vue.config.productionTip = false;
Vue.prototype.$axios = axios;
new Vue({
	i18n,
	router,
	store,
	vuetify,
	render: (h) => h(App),
}).$mount("#app");
