<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import { mapFields } from 'vuex-map-fields';
import i18n from '@/plugins/i18n';
export default {
  watch: {
    language: function (newval) {
     i18n.locale = newval;
    }
  },
  mounted(){
    
  },
     computed: {
           ...mapFields([
      'language'
    ]),
    },
};
</script>

<style lang = "css">
</style>
