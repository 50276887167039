<template>
    <section class="gray-light-bg ptb-100">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-md-9 col-lg-8">
                    <div class="section-heading mb-5 text-center">
                        <h2>{{$t('ourCompany')}}</h2>
                        <p class="lead">
                            {{$t('ourCompanyDesc')}}
                        </p>
                                                <p class="lead">
 {{$t('ourCompanyDesc2')}}
                        </p>
                                                                        <p class="lead">
 {{$t('ourCompanyDesc3')}}

                        </p>
                    </div>
                </div>
            </div>
             <div class="row justify-content-center">
                <div class="col-md-9 col-lg-8">
                    <div class="section-heading mb-5 text-center">
                        <h2>{{$t('ourMission')}}</h2>
                        <p class="lead">
                           {{$t('ourMissionDesc')}}
                        </p>
                    </div>
                </div>
            </div>
                         <div class="row justify-content-center">
                <div class="col-md-9 col-lg-8">
                    <div class="section-heading mb-5 text-center">
                        <h2>{{$t('ourVision')}}</h2>
                       <p class="lead"> {{$t('ourVisionDesc')}}
</p>
<p class="lead">{{$t('ourVisionDesc2')}}

</p>
<p class="lead">{{$t('ourVisionDesc3')}}

</p>
<p class="lead">{{$t('ourVisionDesc4')}}

</p>
                    </div>
                </div>
            </div>
                         <div class="row justify-content-center">
                <div class="col-md-9 col-lg-8">
                    <div class="section-heading mb-5 text-center">
                        <h2>{{$t('ourValue')}}</h2>
                        <p class="lead">
                            {{$t('ourValueDesc')}}
                        </p>
                    </div>
                </div>
            </div>
                <section class="team-single-section ptb-100">
                                        <div class="section-heading mb-5 text-center">
                        <h2>{{$t('Management')}}</h2>
                    </div>
        <div class="container">
            <div class="row align-items-center">
                <div class="col-md-12 col-sm-12 col-lg-5">
                    <div class="team-single-img">
                        <img
                            src="img/team-1.jpg"
                            alt="member"
                            class="img-fluid rounded shadow-sm"
                        />
                    </div>
                </div>
                <div class="col-md-12 col-sm-12 col-lg-6">
                    <div class="team-single-text">
                

                     
                            <p>
{{$t('coFounderDesc')}}
                            </p>
                      
                    </div>
                </div>
            </div>

        </div>
    </section>
           <div class="row justify-content-center">
                <div class="col-md-9 col-lg-8">
                    <div class="section-heading mb-5 text-center">
                        <h2>{{$t('government')}}</h2>
                        <br />
                        <p class="lead">
                           <a href='https://www.labour.gov.hk/tc/service/content4_2.htm' target='blank'>{{$t('labour')}}</a>
                           <br />
                            <a href='https://www.immd.gov.hk/eng/services/visas/foreign_domestic_helpers.html' target='blank'>{{$t('immigration')}}</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "aboutus",
};
</script>

<style>
</style>