<template>
    <section  style="
            background: url('img/hero.jpg') no-repeat  center / cover;
        " class="ptb-100 ">
        <div class="container">
            <div class="row justify-content-center">
                <v-row class="mt-5"  justify="center">
                    <v-col :key="index" v-for="(f,index) in feedbacks" lg="4" cols="12">
                    <v-card  tile>
    <v-card-title>
        <v-icon large>
            mdi-account-circle-outline
         </v-icon>
         <span class="ml-2">{{f.name}}</span>
       
       
    </v-card-title>
    
    <v-card-text >
      <h4>
        {{f.content}}
      </h4>
    </v-card-text>

  </v-card>

  
</v-col>
                </v-row>
          
            </div>
        </div>
       
    </section>
</template>

<script>

export default {
    props : {
        feedbacks : Array
    },
    name: "VideoPromo",

    data: function () {
        return {
            showModal: false,
            rating : 5,
           
        };
    },
    methods: {
        showYouTubeModal: function (e) {
            e.preventDefault();
            this.showModal = true;
        },
    },
};
</script>
<style lang="scss" scoped>

</style>