import Vue from "vue";
import VueRouter from "vue-router";

import Home from "../pages/indices/Home.vue";
import SignupPageOne from "../pages/auth/SignupPageOne.vue";
import SignupPageTwo from "../pages/auth/SignupPageTwo.vue";
import Page404 from "../pages/utilities/Page404.vue";
import ComingsoonPage from "../pages/utilities/ComingsoonPage.vue";
import ThankyouPage from "../pages/ThankyouPage.vue";
import Join from "../pages/joinPage.vue";
import about from "../pages/aboutPage.vue";
import meeting from "../pages/meetingPage.vue";
import services from "../pages/servicesPage.vue";
import contact from "../pages/contactPage.vue";
import Matching from "../pages/matchingPage.vue";
import Confinementservice from "../pages/ConfinementservicePage.vue";
import relatedVideo from "../pages/relatedVideo.vue";

Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		name: "Home",
		component: Home,
	},
	{
		path: "/about",
		name: "about",
		component: about,
	},
	{
		path: "/services",
		name: "services",
		component: services,
	},
	{
		path: "/contact",
		name: "contact",
		component: contact,
	},
	{
		path: "/signup-1",
		name: "signup-1",
		component: SignupPageOne,
	},
	{
		path: "/signup-2",
		name: "signup-2",
		component: SignupPageTwo,
	},
	{
		path: "/404",
		name: "404",
		component: Page404,
	},
	{
		path: "/coming-soon",
		name: "coming-soon",
		component: ComingsoonPage,
	},
	{
		path: "/thank-you",
		name: "thank-you",
		component: ThankyouPage,
	},
	{
		path: "/join",
		name: "join",
		component: Join,
	},
	{
		path: "/matching",
		name: "matching",
		component: Matching,
	},
	{
		path: "/confinementservice",
		name: "confinementservice",
		component: Confinementservice,
	},
	{
		path: "/relatedVideo",
		name: "relatedVideo",
		component: relatedVideo,
	},

	{
		path: "/meeting/:id",
		name: "meeting",
		component: meeting,
	},
];

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes,
	scrollBehavior(to, from, savedPosition) {
		if (to.hash) {
			const element = document.getElementById(to.meta.anchor);
			if (element) {
				return window.scrollTo({
					top: element.offsetTop,
					behavior: "smooth",
				});
			}
		} else if (savedPosition) {
			return savedPosition;
		} else {
			return { x: 0, y: 0 };
		}
	},
});

router.beforeEach((to, from, next) => {
	if (to.meta && to.meta.title) document.title = to.meta.title;
	next();
});

export default router;
