<template>
    <section
        id="team"
        class="team-member-section ptb-100"
    >
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-7 col-md-8">
                    <div class="section-heading text-center mb-5">
                        <h2>{{$t('JoinUs')}}</h2>
                        <p class="lead">
                            
                        </p>
                        <h5>{{$t('job1')}}</h5>
                        <h5>{{$t('job2')}}</h5>
                        <h5>{{$t('job3')}}</h5>
                        <p>{{$t('sendCV')}}<a href='mailto:career@starjasmine.com.hk'>career@starjasmine.com.hk</a></p>
                    </div>
                </div>
            </div>
            <div class="row">

            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "FaqTwo",
};
</script>

<style>
</style>