<template>
  <footer class="footer-section">
    <!--footer top start-->
    <div class="footer-top py-5 background-img-2 bg-footer-custom">
      <div class="container">
        <div class="row justify-content-between">
          <div class="col-lg-3 mb-3 mb-lg-0">
            <div class="footer-nav-wrap text-white">
              <img
                src="img/logo-color-1x.png"
                alt="footer logo"
                width="180"
                class="img-fluid mb-3"
              />
              <p>
                <a
                  href='https://www.eaa.labour.gov.hk/'
                  target='blank'
                >
                  <img
                    :src="$t('logo')"
                    alt="footer logo"
                    width="220"
                    class="img-fluid"
                  />
                </a>
              </p>
              <p>
                <a href='#'>
                  <img
                    :src="$t('logo2')"
                    alt="footer logo"
                    width="220"
                    class="img-fluid"
                  />
                </a>
              </p>

              <div class="social-list-wrap">
                <ul class="social-list list-inline list-unstyled">
                  <li class="list-inline-item">
                    <a
                      href="https://wa.me/85251737224"
                      target="_blank"
                      title="WhatsApp"
                    ><span class="ti-comment"></span></a>
                  </li>
                  &nbsp;
                  <li class="list-inline-item">
                    <a
                      href="https://www.facebook.com/%E6%98%9F%E7%A9%BA%E5%83%B1%E5%82%AD%E4%B8%AD%E5%BF%83-Star-Jasmine-Employment-Centre-102117558909715"
                      target="_blank"
                      title="Facebook"
                    ><span class="ti-facebook"></span></a>
                  </li>
                  &nbsp;

                  <li class="list-inline-item">
                    <a
                      href="https://www.instagram.com/star_jasmine_employment_centre/"
                      target="_blank"
                      title="Instagram"
                    ><span class="ti-instagram"></span></a>
                  </li>
                  &nbsp;

                </ul>
              </div>
            </div>
          </div>
          <div class="col-lg-3 ml-auto mb-4 mb-lg-0">
            <div class="footer-nav-wrap text-white">
              <h5 class="mb-3 text-white">{{ $t('OthersLinks') }}</h5>
              <ul class="list-unstyled">
                <li class="mb-2"><router-link to="/">{{$t('home')}}</router-link></li>
                <li class="mb-2"><router-link to="/about">{{$t('AboutUs')}}</router-link></li>
                <li class="mb-2"><router-link to="/services">{{$t('OurServices')}}</router-link></li>
                <li class="mb-2"><router-link to="/join">{{$t('JoinUs')}}</router-link></li>

                                <li class="mb-2"><router-link to="/contact">{{$t('ContactUs')}}</router-link></li> 
                                 <li class="mb-2"><router-link style='color : yellow' to="/matching">{{$t('Matching')}}</router-link></li> 
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-3 ml-auto mb-4 mb-lg-0">
                        <div class="footer-nav-wrap text-white">
                            <h5 class="mb-3 text-white">{{$t('ContactUs')}}</h5>
                            <ul class="list-unstyled support-list">
                                <a style='color:white' href='https://www.google.com/maps/place/Whampoa+108/@22.3069201,114.1880395,21z/data=!4m6!3m5!1s0x340400de2501be6d:0xba9a2779dff22d46!8m2!3d22.3068418!4d114.1878966!16s%2Fg%2F1tfrdc98?entry=ttu' target='blank'><li class="mb-2 d-flex align-items-center">
                                    <span class="ti-location-pin mr-2"></span>
                                    {{$t('address2')}}
                                    <br />{{$t('address')}}
                                </li></a>
                                <li class="mb-2 d-flex align-items-center">
                                    <span class="ti-mobile mr-2"></span>
                                    <a href="tel:+25599223">
                                        {{$t('tel')}}: 2559 9223</a
                                    >
                                </li>
                                 <li class="mb-2 d-flex align-items-center">
                                    <span class="ti-mobile mr-2"></span
                                    ><a href="tel:+25599700">{{$t('fax')}}: 2559 9700</a>
                                </li>
                                <li class="mb-2 d-flex align-items-center">
                                    <span class="ti-email mr-2"></span
                                    ><a href="mailto:cs@starjasmine.com.hk">
                                        cs@starjasmine.com.hk</a
                                    >
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div class="footer-nav-wrap text-white">
                            <h5 class="mb-3 text-white">{{$t('Location')}}</h5>
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d230.70223768530886!2d114.1880394986012!3d22.306920125971676!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x340400de2501be6d%3A0xba9a2779dff22d46!2sWhampoa%20108!5e0!3m2!1sen!2sus!4v1715742327660!5m2!1sen!2sus" width="400" height="250" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    </div>
                    <div class="col-lg-3">
                    </div>
                    <div class="col-lg-3 ml-auto mb-4 mb-lg-0">
                        <div class="footer-nav-wrap text-white">
                            <h5 class="mb-3 text-white">{{$t('ContactUs')}}</h5>
                            <ul class="list-unstyled support-list">
                                <a style='color:white' href='https://www.google.com/maps/place/OK+Mall/@22.3882325,114.205456,15z/data=!4m6!3m5!1s0x34040752f043ad73:0xef7ce281921a7bed!8m2!3d22.3887879!4d114.2085025!16s%2Fg%2F11fcr3rdvj?hl=en&entry=ttu' target='blank'><li class="mb-2 d-flex align-items-center">
                                    <span class="ti-location-pin mr-2"></span>
                                    {{$t('address4')}}
                                    <br />{{$t('address3')}}
                                </li></a>
                                <li class="mb-2 d-flex align-items-center">
                                    <span class="ti-mobile mr-2"></span>
                                    <a href="tel:+98484244">
                                        {{$t('tel')}}: 9848 4244</a
                                    >
                                </li>
                               
                                <li class="mb-2 d-flex align-items-center">
                                    <span class="ti-email mr-2"></span
                                    ><a href="mailto:cs@starjasmine.com.hk">
                                        cs@starjasmine.com.hk</a
                                    >
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div class="footer-nav-wrap text-white">
                            <h5 class="mb-3 text-white">{{$t('Location')}}</h5>
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3689.0690707162626!2d114.20611682687345!3d22.388753439680443!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x34040752f043ad73%3A0xef7ce281921a7bed!2sOK%20Mall!5e0!3m2!1sen!2sus!4v1715742710340!5m2!1sen!2sus" width="400" height="250" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    </div>
                </div>
            </div>
          </div>
 
    

    <!--footer top end-->

    <!--footer copyright start-->
    <div class="footer-bottom gray-light-bg pt-4 pb-4">
      <div class="container">
        <div class="row text-center justify-content-center">
          <div class="col-md-6 col-lg-6">
            <p class="copyright-text pb-0 mb-0">
              Copyrights © 2021 All rights reserved by Star Jasmine Employment Centre
            </p>
          </div>
        </div>
      </div>
    </div>
    <!--footer copyright end-->
  </footer>
</template>

<script>
export default {
  name: "SiteFooter",
};
</script>

<style scoped lang="scss">
.bg-footer-custom {
  background: rgb(72, 168, 176);
  background: linear-gradient(
    41deg,
    rgba(72, 168, 176, 1) 38%,
    rgba(242, 148, 111, 1) 76%
  );
}
</style>