<template>
    <section
        class="hero-section bg"
    >
            <div class="ptb-100">
                <div class="container">
                    <div class="row align-items-center justify-content-center">
                        <div class="col-md-8 col-lg-7">
                            <div
                                class="hero-content-left text-white text-center  ptb-100"
                            >
                                <h1 class="text-white" style='font-weight: 600'>
                                    {{$t('heroMsg')}}
                                </h1>
                                <router-link class="btn google-play-btn"
  to="/matching">{{$t('MatchNow')}}</router-link>
                            </div>
                        </div>
                    </div>
                 
                </div>
            </div>
    </section>
</template>

<script>
import $ from "jquery";

export default {
    name: "Banner",
    mounted() {
        // $("#heroPlayer").YTPlayer();
        this.player = $("#heroPlayer").YTPlayer();
        setTimeout(() => {
            this.player.YTPlayer();
        }, 2000);
    },
};
</script>

<style>
.bg {
    background-image: url("hero.jpg");
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; 
}
</style>