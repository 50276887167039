<template>
  <div>

    <div class="main">
      <section
        class="hero-section ptb-100 background-img"
        style="
            background: url('img/hero.jpg') no-repeat center center / cover;
        "
      >
        <div class="container">
          <div class="row align-items-center justify-content-center">
            <div class="col-md-9 col-lg-7">
              <div class="page-header-content text-white text-center pt-sm-5 pt-md-5 pt-lg-0">
                <h1 class="text-white mb-0"> Meeting Link</h1>

              </div>
            </div>
          </div>
        </div>
      </section>
      <section style='height : 600px'>
        <div class="container">
          <div
            class='align-items-center justify-content-center'
            style='margin-top : 250px ;'
          >
            <div v-if='!loading'>
              <h5 style='color : red'>Something is wrong with your meeting ID, please try again by entering your meeting id below </h5>
              <h5 style='color : red'>您的會議ID有問題, 請嘗試在下方重新輸入您的會議ID。</h5>
              <v-text-field
                v-model='newMeetingID'
                label='Please Enter Meeting ID'
              >
              </v-text-field>
              <v-btn @click='reconnect'>
                Connect
              </v-btn>
            </div>
            <h4 v-else>

              <v-progress-circular
                :size="50"
                color="primary"
                indeterminate
                class='mr-3'
              ></v-progress-circular>Please wait while we validate your meeting ID
            </h4>
          </div>
        </div>
      </section>
      <site-footer />
    </div>
  </div>
</template>

<script>
import SiteFooter from "../views/index-five/SiteFooter";
import { VideoSDKMeeting } from "@videosdk.live/rtc-js-prebuilt";
export default {
  async mounted() {
    let check = await this.validateMeetingID(this.$route.params.id);
    if (check) {
      this.startMeeting(this.token);
    }
  },
  data() {
    return {
      loading: true,
      connectLoading: false,
      token: null,
      newMeetingID: null,
    };
  },
  components: {
    SiteFooter,
  },
  methods: {
    async reconnect() {
      this.connectLoading = true;
      await this.validateMeetingID(this.newMeetingID);
      this.connectLoading = false;
      this.startMeeting(this.token);
    },
    async validateMeetingID(meetingId) {
      try {
        let r = await this.$axios.get(
          "https://us-central1-starjasmine-aacba.cloudfunctions.net/validateMeetingRoom?id=" +
            meetingId
        );
        this.token = r.data.token;
        if (this.token === "") {
          this.loading = false;
        }
        return true;
      } catch (e) {
        return false;
      }
    },
    startMeeting(token) {
      const name = "Admin";
      const config = {
        name: name,
        meetingId: this.$route.params.id,
        token: token,
        region: "sg001", // region for new meeting
        containerId: null,
        redirectOnLeave: "https://starjasmine.com.hk/",
        micEnabled: true,
        webcamEnabled: true,
        participantCanToggleSelfWebcam: true,
        participantCanToggleSelfMic: true,
        participantCanLeave: true, // if false, leave button won't be visible
        chatEnabled: true,
        screenShareEnabled: true,
        pollEnabled: false,
        whiteboardEnabled: false,
        raiseHandEnabled: false,
        recording: {
          autoStart: false, // auto start recording on participant joined
          enabled: false,
        },
        livestream: {
          autoStart: false,
          enabled: false,
        },
        layout: {
          type: "SPOTLIGHT", // "SPOTLIGHT" | "SIDEBAR" | "GRID"
          priority: "PIN", // "SPEAKER" | "PIN",
          // gridSize: 3,
        },
        branding: {
          enabled: true,
          logoURL: "https://starjasmine.com.hk/img/logo-white-1x.png",
          name: "Prebuilt",
          poweredBy: false,
        },
        permissions: {
          pin: true,
          askToJoin: false, // Ask joined participants for entry in meeting
          toggleParticipantMic: true, // Can toggle other participant's mic
          toggleParticipantWebcam: true, // Can toggle other participant's webcam
          drawOnWhiteboard: false, // Can draw on whiteboard
          toggleWhiteboard: false, // Can toggle whiteboard
          toggleRecording: false, // Can toggle meeting recording
          toggleLivestream: false, //can toggle live stream
          removeParticipant: true, // Can remove participant
          endMeeting: true, // Can end meeting
          changeLayout: true, //can change layout
        },
        joinScreen: {
          visible: true, // Show the join screen ?
          title: "Meeting", // Meeting title
          meetingUrl: window.location.href, // Meeting joining url
        },
        leftScreen: {
          // visible when redirect on leave not provieded
          actionButton: {
            // optional action button
            label: "Video SDK Live", // action button label
            href: "https://videosdk.live/", // action button href
          },
        },
        notificationSoundEnabled: true,
        debug: true, // pop up error during invalid config or netwrok error
        maxResolution: "sd", // "hd" or "sd"
      };
      const meeting = new VideoSDKMeeting();
      meeting.init(config);
    },
  },
};
</script>

