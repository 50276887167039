<template>
    <div>
      <v-row class="mt-10">
        <v-col xl='2' lg="2" cols="12">
            <p> {{$t("maidType")}}</p>

<v-btn-toggle  v-model="maidType">
  <v-btn value="Oversea">
   {{$t("OverseaShort")}}
  </v-btn>

  <v-btn value="Local">
    {{$t("LocalShort")}}
  </v-btn>

 
</v-btn-toggle>
        </v-col>


        <v-col cols="3">
            <p>{{$t("nationality")}}</p>

<v-btn-toggle  v-model="maidNationality">
  <v-btn value="Indonesian">
    {{$t("Indonesian")}}
  </v-btn>

  <v-btn value="Filipino">
    {{$t("Filipino")}}
  </v-btn>

 
</v-btn-toggle>
        </v-col>

        <v-col xl='3' lg="3"  cols="12">
            <p> {{$t("age")}}</p>

<v-btn-toggle  v-model="maidAge">
  <v-btn value="21-30">
    21-30
  </v-btn>
  <v-btn value="31-40">
    31-40
  </v-btn>
  <v-btn value="41+">
    41+
  </v-btn>

 
</v-btn-toggle>
        </v-col>
        <v-col>
          <p>{{$t("skill")}}</p>
          <v-btn-toggle multiple v-model="maidSkill">
        <v-btn title="new born" value="newborn">
          <v-icon>mdi-baby-carriage</v-icon>
        </v-btn>

        <v-btn title="child" value="child">
          <v-icon>mdi-human-male-child</v-icon>
        </v-btn>

        <v-btn title="elderly" value="elderly">
          <v-icon>mdi-human-cane</v-icon>
        </v-btn>

        <v-btn title="disable" value="disable">
          <v-icon>mdi-human-wheelchair</v-icon>
        </v-btn>
      </v-btn-toggle>
        </v-col>
      </v-row>
    
      <v-btn @click="filterResult" class='mt-5' color='primary' block>
        Filter
      </v-btn>
      <v-data-iterator
      class="mt-5"
      :loading="loading"
        :items="items"
        :items-per-page.sync="itemsPerPage"
        :page.sync="page"
        :search="search"
        hide-default-footer
      >
   
  
        <template v-slot:default="props">
          <v-row>
            <v-col
              v-for="item in props.items"
              :key="item.name"
              cols="12"
              sm="6"
              md="3"
              lg="3"
            >

          

              <v-card tile>
                <v-list-item-avatar
               tile
               
        size="230"
        color="grey"
      >
      <v-img
            v-if='item.fileLocation.includes("/null")'
            src="../../assets/notyet.jpg"
           
          />
                <v-img v-else
            :src=' `https://storage.googleapis.com/starjasmine-aacba.appspot.com/${item.fileLocation}`'
           
          />
    
    </v-list-item-avatar>
                <v-card-title class="subheading font-weight-bold">
                  {{ item.name }}
                  <v-btn class='mt-3' small block @click="downloadPDF(item)">
                        {{$t('downloadBioData')}}
                      </v-btn>
                </v-card-title>
  
                <v-divider></v-divider>
  
                <v-list dense>
                  <v-list-item
                    v-for="(key, index) in filteredKeys"
                    :key="index"
                  >
                    <v-list-item-content :class="{ 'blue--text': sortBy === key }">
                      {{ titlechange(key) }}:
                    </v-list-item-content>
                    <v-list-item-content
                      class="align-end"
                      :class="{ 'blue--text': sortBy === key }"
                    >
                      {{ item[key] }}
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
                
              </v-card>
            </v-col>
          </v-row>
        </template>
  
        <template v-slot:footer>
          <v-row
            class="mt-2"
            align="center"
            justify="center"
          >
            <span class="grey--text">Items per page</span>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  dark
                  text
                  color="primary"
                  class="ml-2"
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ itemsPerPage }}
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="(number, index) in itemsPerPageArray"
                  :key="index"
                  @click="updateItemsPerPage(number)"
                >
                  <v-list-item-title>{{ number }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
  
            <v-spacer></v-spacer>
  
            <span
              class="mr-4
              grey--text"
            >
              Page {{ page }} of {{ numberOfPages }}
            </span>
            <v-btn
              fab
              dark
              color="blue darken-3"
              class="mr-1"
              @click="formerPage"
            >
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <v-btn
              fab
              dark
              color="blue darken-3"
              class="ml-1"
              @click="nextPage"
            >
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </v-row>
        </template>
      </v-data-iterator>
    </div>
  
   
  </template>


<script>
import axios from "axios";
  export default {
    watch : {
   
    },
    async mounted(){
        let data = await axios.get(
          "https://asia-east2-starjasmine-aacba.cloudfunctions.net/matching/gethelpers"
        );
        this.items = data.data;
        this.rawItems = data.data;
        this.loading = false;
    },
    data () {
      return {
        loading : true,
        maidSkill : [],
        rawItems : [],
        maidType : null,
        maidNationality: null,
        maidAge : null,
        items : [],
        itemsPerPageArray: [10,   50, 100],
        search: '',
        filter: {},
        sortDesc: false,
        page: 1,
        itemsPerPage: 12,
        sortBy: 'name',
        keys: [
          'Name',
          'portfolioId',
          'nationality',
          'age',
        
        ],
      
      }
    },
    computed: {
      numberOfPages () {
        return Math.ceil(this.items.length / this.itemsPerPage)
      },
      filteredKeys () {
        return this.keys.filter(key => key !== 'Name')
      },
    },
    methods: {
      titlechange(k){
        switch(k){
          case "portfolioId":
          return this.$t('PortfolioID');
          case "nationality":
          return this.$t('nationality');
          case "age":
            return this.$t('age');
          default:
            return k;
          
        }
      },
      downloadPDF(profile)  {
        console.log(profile);
        window.open('https://asia-east2-starjasmine-aacba.cloudfunctions.net/matching/url?p=AITI-KI-0028&url=https://admin.starjasmine.com.hk/fetch/'+ profile.id);
      },
      filterResult(){
        this.items = this.rawItems;
        console.log(this.maidSkill)
        if(this.maidType){
          this.items = this.items.filter(i => i.status === this.maidType)
        }
        if(this.maidNationality){
          this.items = this.items.filter(i => i.nationality === this.maidNationality)
        }
        if(this.maidAge){
          switch(this.maidAge){
            case "21-30" :
            this.items = this.items.filter(i => i.age >= 21 && i.age <= 30);
              break;
              case "31-40" :
              this.items = this.items.filter(i => i.age >= 31 && i.age <= 40);
              break;
              case "41+" :
              this.items = this.items.filter(i => i.age >= 41);
              break;
          }
        }
        if(this.maidSkill.length > 0){
          if(this.maidSkill.includes("newborn")){
            this.items = this.items.filter(i => i.careInfant >= 3);
          }
          if(this.maidSkill.includes("child")){
            this.items = this.items.filter(i => i.careChild >= 3);
          }
          if(this.maidSkill.includes("elderly")){
            this.items = this.items.filter(i => i.careElderly >= 3);
          }
          if(this.maidSkill.includes("disable")){
            this.items = this.items.filter(i => i.careDisabled >= 3);
          }
        }
          //maidSkill
      },
      nextPage () {
        if (this.page + 1 <= this.numberOfPages) this.page += 1
      },
      formerPage () {
        if (this.page - 1 >= 1) this.page -= 1
      },
      updateItemsPerPage (number) {
        this.itemsPerPage = number
      },
    },
  }
</script>