import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

const messages = {
	en: {
		heroMsg:
			"Modern AI matching system to help you find the best home for the best oversea domestic helpers",
		comingSoon: "Coming Soon.....",
		home: "Home",
		AboutUs: "About Us",
		OurServices: "Our Services",
		JoinUs: "Join Us",
		ContactUs: "Contact Us",
		Matching: "Matching",
		MatchNow: "Match Now",
		Why: "Why Star Jasmine?",
		WhyReason:
			"Located at the heart of Hong Kong, we are a employment agency focusing on using IT to match the best-fit oversea domestic helper to the best-fit employer in Hong Kong.",
		AI: "AI matching",
		AIReason:
			"Using Modern Information Technology to match the best home for the best oversea domestic helpers in Hong Kong.",
		domesticHelper: "Comprehensive Domestic Helper Services",
		domesticHelperReason:
			"We provide comprehensive domestic helper services to our customers.",
		servicesReason:
			"Matching the best-fit domestic helper to the best-fit employer.",
		bestfit: "Best-fit",
		bestfitReason:
			"Matching the best-fit domestic helper to the best-fit employer.",
		howitworks: "How Our Match System Works",
		watchVideoPreview: "Watch video overview",
		getStarted: "Get Started",
		Contract: "Contract renewal",
		ContractDesc:
			"Since the helper and employer are perfect match, we provide contract renewal services for your helper.",
		Oversea: "Overseas Domestic helpers",
		OverseaShort: "Overseas",
		OverseaDesc:
			"One-stop service from matching, training, departure, and arrival.",
		Local: "Local helpers",
		LocalShort: "Local",
		LocalDesc:
			" Don’t want to wait too long, we have list of local helpers that can match with our customer and we also provide one-stop service for local helpers.",
		address: "SHOP 18, G/F, WHAMPOA 108 SHOPPING CENTRE",
		address2: "19-23 MAN TAI ST, KLN, H.K.",
		address3: "Shop 138B02, First Floor, OK Mall, Kings Wing Plaza 2",
		address4: "No. 1 On Kwan Street, Shatin, New Territories ",
		tel: "Tel",
		fax: "Fax",
		Location: "Location",
		ourCompany: "Our Company:",
		ourCompanyDesc:
			"Located at the heart of Hong Kong, we are a employment agency focusing on matching the best-fit oversea domestic helper to the best-fit employer in Hong Kong.",
		ourCompanyDesc2:
			"To achieve this goal, we utilize modern information technology, including AI matching and big data analysis to facilitate the matching process. Our cofounder has successfully operating several IT firms, with his experience, we are committed to provide the best matching process and result for both the domestic helper and employer.",
		ourCompanyDesc3:
			"The company’s official and registered address is located at SHOP 18, G/F, WHAMPOA 108 SHOPPING CENTRE,  19-23 MAN TAI ST, KLN, H.K.",
		ourMission: "Our Mission:",
		ourMissionDesc:
			"Using Modern Information Technology to match the best home for the best oversea domestic helpers in Hong Kong.",
		ourVision: "Our Vision:",
		ourVisionDesc:
			"- To be the leading employment agency in using modern information technology including big data analysis and AI matching in matching process.",
		ourVisionDesc2:
			"- To provide the best matching process for both the employer and domestic worker.",
		ourVisionDesc3:
			"- The Best after-sales services including case-by-case follow-up on domestic helper work ethics.",
		ourVisionDesc4:
			"- To improve the preciseness of our matching process by collecting feedback from domestic helpers and employers.",
		ourValue: "Our Value:",
		ourValueDesc:
			"highest standards of ethics and integrity. Star Jasmine is committed towards its workers and employers.",
		Management: "Management Team:",
		coFounder: "Co-founder",
		coFounderDesc:
			"Mr. Tai has successfully operating several IT firms in Hong Kong. Most of the employment agencies in Hong Kong still haven’t implicate more advanced information technology to facilitate the matching process between the domestic helpers and customers. With his experience, he is committed and well prepared to provide a better matching process by implicating such advanced information technology including AI matching and big data analysis.",
		government: "Government Regulators:",
		labour: "Labour Department - Employment Agencies Administration",
		immigration: "Immigration Department – Visa / Entry Permits",
		joinUsDesc:
			"We are expanding, want to work for a rapid growing company? <br />we are looking for talent below:",
		job1: "Branch Operation Manager",
		job2: "Branch Sales Representative",
		job3: "Online Marketing Expert",
		sendCV: "Send your CV to ",
		contactuspage:
			"Located at the heart of Hong Kong, below is our contact information:",
		headoffice: "Head Office",
		email: "Email",
		allinone: "ALL IN ONE FEE:",
		ContractDesc1: "Application fee for working visa at Immigration Department",
		ContractDesc2: "Consular fee at consulate general",
		ContractDesc3:
			"Medical examination in Hong Kong (includes pregnancy test, Hepatitis B, Chest X-ray, syphilis, HIV and stool analysis)",
		OverSeaDetailDesc1: "Medical examination before departure",
		OverSeaDetailDesc2: "One-way air ticket to Hong Kong (including taxes)",
		OverSeaDetailDesc3: "Consular fee at consulate general",
		OverSeaDetailDesc4:
			"Application fee for working visa at Immigration Department",
		OverSeaDetailDesc5: "Applying for HKID",
		OverSeaDetailDesc6: "Reporting to their consulate",
		OverSeaDetailDesc7:
			"Medical examination in Hong Kong (includes pregnancy test, Hepatitis B, Chest X-ray, syphilis, HIV and stool analysis)",
		localDetailDesc1:
			"Application fee for working visa at Immigration Department",
		localDetailDesc2: "Consular fee at consulate general",
		localDetailDesc3:
			"Medical examination in Hong Kong (includes pregnancy test, Hepatitis B, Chest X-ray, syphilis, HIV and stool analysis)",
		OthersLinks: "Other Links",
		logo: "img/Labour_DepartmentEng.png",
		logo2: "img/ph_Labour_Department_eng.png",
		wizardStep1: "Select Skills (Please Select all Applicable)",
		wizardStep2: "Select Personality Preferece (Please Select all Applicable)",
		wizardStep3: "Basic Info",
		continue: "Continue",
		back: "Back",
		cleanliness: "Cleanliness",
		discipline: "Discipline",
		empathy: "Empathy",
		efficiency: "Efficiency",
		friendliness: "Friendliness",
		hardworking: "Hardworking",
		honesty: "Honesty",
		patience: "Patience",
		proactiveness: "Proactiveness",
		responsible: "Responsible",
		personalityWarning: "Please Select up to 5 selections only.",
		careInfantShort: "Infant",
		careChildShort: "Child",
		careElderlyShort: "Elderly",
		careDisabledShort: "Disabled Person",
		careBedriddenShort: "Bedridden Person",
		carePetShort: "Pet",
		cookingShort: "Cooking",
		generalHouseworkShort: "General Housework",
		carWashingShort: "Car Washing",
		careInfant: "Care of Infant",
		careChild: "Care of Child",
		careElderly: "Care of Elderly",
		careDisabled: "Care of Disabled Person",
		careBedridden: "Care of Bedridden Person",
		carePet: "Care of Pet",
		cooking: "Cooking",
		generalHousework: "General Housework (e.g. Cleaning, Washing & Ironing)",
		carWashing: "Car Washing",
		familyMember: "Number of Family Member*",
		prevExp: "Previous Experience of hiring helper*",
		privateRoom: "Domestic helper need to share room with family member*",
		helperTypeValue: ["Local", "OverSea", "No Preferece"],
		helperType: "Type of Helper needed*",
		complete: "Complete",
		skillWarning: "Please Select at least 1 checkbox",
		yesno: ["yes", "no"],
		matchResult: "Your Match Result:",
		nationality: "Nationality",
		maidType: "Type of Helper",
		age: "Age",
		religion: "Religion",
		marriage: "Marital Status",
		educationLevel: "Education Level",
		height: "Height",
		weight: "Weight",
		language: "Language Skills",
		workingExp: "Work Experience",
		personalityChartTitle: "Strength",
		skillChartTitle: "Skill",
		skill: "Skills",
		youChoice: "You Choice",
		Filipino: "Filipino",
		Indonesian: "Indonesian",
		Others: "Others",
		"Junior High": "Junior High",
		"High School": "High School",
		College: "College",
		Single: "Single",
		Married: "Married",
		Separated: "Separated",
		Christian: "Christian",
		"Roman Catholic": "Roman Catholic",
		Protestantism: "Protestantism",
		Muslim: "Muslim",
		english: "English",
		mandarin: "Mandarin",
		cantonese: "Cantonese",
		poor: "Need to learn",
		fair: "Fair",
		good: "Good",
		excellent: "Excellent",
		duration: "Year(s)",
		Philippines: "Philippines",
		Indonesia: "Indonesia",
		"Hong Kong": "Hong Kong",
		Taiwan: "Taiwan",
		"Middle East": "Middle East",
		Singapore: "Singapore",
		name: "Name",
		helperNumber: "ID:",
		reset: "Reset",
		wizardStep4: "Result",
		contactInfo:
			"Please leave your phone or email below if you would like additonal info of this helper.",
		provideNum:
			"OR you can contact us via the following info, (Please provide Match Result #)",
		submit: "Submit",
		read: "I have read and understand",
		privacyNotice: " the privacy notice.",
		link:
			"https://storage.googleapis.com/starjasmine-aacba.appspot.com/eng%20privacy%20notice.pdf",
		noMatchResult:
			"Seems like we cannot find a match for you using AI, let’s contact with our representatives to find out more or try modify your match criterias.",
		or: "or",
		lastName: "Last Name",
		more1: "There",
		more2:
			"more suitable candidates that fit your criteria, contact us now to learn more.",
		confinementNanny: "Confinement Nanny",
		relatedVideo: "Related Video",
		downloadBioData: "Download Bio Data",
		PortfolioID: "Portfolio #",
	},
	"zh-HK": {
		heroMsg:
			"坐立於香港的中心，我們致力於運用資訊科技，為你的家找到最合適的海外僱傭。",
		comingSoon: "正在建構中.....",
		home: "首頁",
		AboutUs: "關於我們",
		OurServices: "我們的服務",
		JoinUs: "加入我們",
		ContactUs: "聯絡我們",
		MatchNow: "進行配對",
		Matching: "配對",
		Why: "星空僱傭中心：",
		WhyReason:
			"坐立於香港的中心，我們致力於運用資訊科技，為你的家找到最合適的海外僱傭。為了成功配對合適的海外僱傭給我們的顧客，我們使用不同嶄新的資訊科技，當中包括AI及大數據分析來把僱主的要求及喜好與合適的外傭作出配對。我們的創辦人在香港經營數間資訊科技公司，以他的營運經驗，及運用資訊科技的前瞻性，我們定能提供最優化的配對服務。",
		AI: "AI 配對",
		AIReason: "大數據分析,個案跟進",
		domesticHelper: "運用資訊科技，為你的家找到最合適的海外僱傭。",
		domesticHelperReason: "我們為客戶提供最完善的家庭僱工服務",
		servicesReason: "為最合適的僱主找到最合適的家庭僱工",
		bestfit: "最合適",
		bestfitReason: "為你的家配對合適的海外僱傭。",
		howitworks: "了解我們的配對系統",
		watchVideoPreview: "簡介短片",
		getStarted: "展開行動",
		Contract: "續約服務",
		ContractDesc: "找到匹配的外傭，我們為你提供續約服務",
		Oversea: "海外僱傭",
		OverseaShort: "海外僱傭",
		OverseaDesc: "提供由配對，培訓到到埗的一站式服務",
		Local: "本地外傭",
		LocalShort: "本地外傭",
		LocalDesc: "緊急需要外傭工作，我們有不同外傭可供選擇，為你提供一站式服務",
		address: "黃埔108商場18號舖",
		address2: "九龍紅磡民泰街19-23號",
		address3: "OK Mall 1樓138B02號舖",
		address4: "沙田安群街1號京瑞廣場2期",
		tel: "電話",
		fax: "傳真",
		Location: "地址",
		ourCompany: "星空僱傭中心：",
		ourCompanyDesc:
			"坐立於香港的中心，我們致力於運用資訊科技，為你的家找到最合適的海外僱傭。",
		ourCompanyDesc2:
			"為了成功配對合適的海外僱傭給我們的顧客，我們使用不同嶄新的資訊科技，當中包括AI及大數據分析來把僱主的要求及喜好與合適的外傭作出配對。我們的創辦人在香港經營數間資訊科技公司，以他的營運經驗，及運用資訊科技的前瞻性，我們定能提供最優化的配對服務。",
		ourCompanyDesc3: "本公司地址為 九龍紅磡民泰街19-23號. 黃埔108商場18號舖",
		ourMission: "我們的使命:",
		ourMissionDesc: "運用嶄新的資訊科技，為你的家找到最合適的海外僱傭。",
		ourVision: "我們的願景:",
		ourVisionDesc:
			"- 在運用嶄新的資訊科技包括AI及大數據分析作出配對範疇領先同儕的香港僱傭中心。",
		ourVisionDesc2: "- 提供最匹配的外傭及僱主配對服務。",
		ourVisionDesc3: "- 提供最優質的個案跟進及監測外傭的職業道德。",
		ourVisionDesc4:
			"- 收集客戶及外傭的意見，強化大數據資料庫，提高匹配準確度。",
		ourValue: "我們的價值觀：",
		ourValueDesc: "星空僱傭中心對所有持份者承諾維持最高程度的職業道德及操手。",
		Management: "管理團隊：",
		coFounder: "創辦人",
		coFounderDesc:
			"我們的團隊有多年在港經營公司的經驗，當中數間為資訊科技公司。經觀察後，發現現時大部分香港的僱傭中心還未充分運用嶄新的資訊科技來為客戶找到最合適的海外僱傭。有見及此，我們的團隊希望能運用成功的經驗帶到星空僱傭中心，致力於運用資訊科技來提高匹配過程的成功率及準確性，為你的家找到最合適的海外僱傭。",
		government: "相關政府機構:",
		labour: "勞工處 - 職業介紹所事務科",
		immigration: "入境處 - 外籍家庭傭工的入境簽證",
		joinUsDesc: "我們正在擴展中，想加入我們的團隊？ 以下為現有職位空缺：",
		job1: "營運經理",
		job2: "銷售代表",
		job3: "網上市場推廣專員",
		sendCV: "如有意申請以上職位，請把履歷傳送至 ",
		contactuspage: " 位於香港的核心地段，以下為我們的聯絡資料：",
		headoffice: "總公司",
		email: "電郵",
		allinone: "一站式服務收費:",
		ContractDesc1: "在香港入境處申請入境工作簽證的費用",
		ContractDesc2: "駐港領事館的手續費用",
		ContractDesc3:
			"在港驗身 (包括胸肺X光、乙型肝炎、梅毒、愛滋病病毒檢驗及驗孕)",
		OverSeaDetailDesc1: "海外驗身",
		OverSeaDetailDesc2: "到港單程機票 (包括稅務)",
		OverSeaDetailDesc3: "駐港領事館的合約及核實費用",
		OverSeaDetailDesc4: "在香港入境處申請工作簽證的費用",
		OverSeaDetailDesc5: "協助外傭辦理香港身份證",
		OverSeaDetailDesc6: "到駐港領事館報到",
		OverSeaDetailDesc7:
			"到港後驗身 (包括胸肺X光、乙型肝炎、梅毒、愛滋病病毒檢驗及驗孕)",
		localDetailDesc1: "在香港入境處申請入境工作簽證的費用",
		localDetailDesc2: "駐港領事館的手續費用",
		localDetailDesc3:
			"在港驗身 (包括胸肺X光、乙型肝炎、梅毒、愛滋病病毒檢驗及驗孕)",
		OthersLinks: "其他連結",
		logo: "img/Labour_Department.svg.png",
		logo2: "img/ph_Labour_Department.png",
		wizardStep1: "所需技能 (可選填多項)",
		wizardStep2: "所需性格特長 (最多選填1-5個)",
		wizardStep3: "基本資料",
		continue: "繼續",
		back: "上一頁",
		cleanliness: "整潔",
		discipline: "有紀律",
		empathy: "同理心",
		efficiency: "高效率",
		friendliness: "友善",
		hardworking: "勤力",
		honesty: "誠實, 可靠",
		patience: "有耐性",
		proactiveness: "主動",
		responsible: "負責任",
		personalityWarning: "請選擇五個或更少選項",
		skillWarning: "請選擇一個或更多的選項",
		careInfant: "照顧幼兒",
		careChild: `照顧小童`,
		careElderly: `照顧老人`,
		careDisabled: `照顧傷殘人士`,
		careBedridden: `照顧病人`,
		carePet: `照顧動物`,
		cooking: `烹飪`,
		generalHousework: `一般家務`,
		carWashing: `洗車`,
		careInfantShort: "照顧幼兒",
		careChildShort: "照顧小童",
		careElderlyShort: "照顧老人",
		careDisabledShort: "照顧傷殘人士",
		careBedriddenShort: "照顧病人",
		carePetShort: "照顧動物",
		cookingShort: "烹飪",
		generalHouseworkShort: "一般家務",
		carWashingShort: "洗車",
		familyMember: "家庭成員數*",
		prevExp: "聘任外傭經驗*",
		privateRoom: "家庭傭工需與家中成員同房*",
		helperTypeValue: ["現成", "海外", "兩者皆可"],
		helperType: "需要現成/海外傭工*",
		complete: "完成",
		yesno: ["是", "否"],
		matchResult: "你的配對結果",
		nationality: "國籍",
		maidType: "女傭類型",
		age: "年齡",
		religion: "宗教",
		marriage: "婚姻",
		educationLevel: "教育程度",
		height: "高度",
		weight: "體重",
		language: "語文能力",
		workingExp: "工作經驗",
		personalityChartTitle: "優點與強項",
		skillChartTitle: "技能 （4=最高）",
		skill: "技能",
		youChoice: "你選擇了",
		Filipino: "菲律賓",
		Indonesian: "印尼",
		Others: "其他",
		"Junior High": "中學",
		"High School": "高中",
		College: "大學",
		Single: "單身",
		Married: "已婚",
		Separated: "已分開",
		Christian: "基督教",
		"Roman Catholic": "天主教",
		Protestantism: "基督新教",
		Muslim: "伊斯蘭教",
		english: "英語",
		mandarin: "普通話",
		cantonese: "廣東話",
		poor: "有待學習",
		fair: "一般",
		good: "流利",
		excellent: "非常流利",
		duration: "年",
		Philippines: "菲律賓",
		Indonesia: "印度尼西亞",
		"Hong Kong": "香港",
		Taiwan: "台灣",
		"Middle East": "中東",
		Singapore: "新加坡",
		name: "外傭名稱",
		helperNumber: "編號:",
		reset: "重新開始",
		wizardStep4: "結果",
		contactInfo: "如欲收取該傭工更詳細的資訊, 請留下聯絡資料",
		provideNum: "或可與我們的團隊聯絡 (請提供配對結果#)",
		submit: "提交",
		read: "我已閱讀",
		privacyNotice: "個人資料收集聲明",
		link:
			"https://storage.googleapis.com/starjasmine-aacba.appspot.com/chn%20privacy%20notice.pdf",
		noMatchResult:
			"我們的AI 系統未能找到匹配的外傭，請與我們聯絡或嘗試變更你的配對條件.",
		or: "或",
		lastName: "專姓",
		more1: "根據電腦分析，還有",
		more2:
			"位適合你家庭的外傭，如欲收取該傭工更詳細的資訊及了解更多詳情, 請留下聯絡資料",
		confinementNanny: "陪月",
		relatedVideo: "相關影片",
		downloadBioData: "下載外傭資料",
		PortfolioID: "外傭 ID",
	},
};
// eslint-disable-next-line no-unused-vars
export default new VueI18n({
	locale: "zh-HK", // set locale
	fallbackLocale: "en", // set fallback locale
	messages, // set locale messages
});
