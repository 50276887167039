<template>
    <header class="header">
        <!--start navbar-->
        <nav
            class="navbar navbar-expand-lg fixed-top"
            v-bind:class="{
                affix: hasAffix,
                'custom-nav': coloredLogo,
                'bg-transparent': !coloredLogo,
                'white-bg': coloredLogo,
            }"
        >
            <div class="container">
                   <router-link
                                class="navbar-brand"
                                to="/"
                                ><img
                        :src="
                            coloredLogo
                                ? 'img/logo-color-1x.png'
                                : 'img/logo-white-1x.png'
                        "
                        width="200"
                        alt="logo"
                        class="img-fluid pb-4"
                /></router-link>
               

                <button
                    class="navbar-toggler"
                    type="button"
                    @click="mobileNavClicked"
                    v-bind:class="{ collapsed: collapsed }"
                >
                    <span class="ti-menu"></span>
                </button>

                <div
                    class="collapse navbar-collapse main-menu h-auto"
                    v-bind:class="{ show: !collapsed }"
                    id="navbarSupportedContent"
                >
                    <ul class="navbar-nav ml-auto">
                        <li class="nav-item dropdown bold">
                        
                                <v-btn
  text dark @click='()=>{$router.push("/")}'
>{{$t('home')}}</v-btn>
                            
                        </li>
                        <li class="nav-item nav-link page-scroll">
                            <v-btn
  text :dark='!$route.name.includes("about")' @click='()=>{$router.push("/about")}'
>{{$t('AboutUs')}}</v-btn>
                         
                        </li>

                        <li class="nav-item">
                                                       <v-btn
  text :dark='!$route.name.includes("services")' @click='()=>{$router.push("/services")}'
>{{$t('OurServices')}}</v-btn>
                          
                        </li>
                        <li class="nav-item">
                                                               <v-btn 
  text :dark='!$route.name.includes("join")' @click='()=>{$router.push("/join")}'
>{{$t('JoinUs')}}</v-btn>
                          
                            
                        </li>


                        <li class="nav-item">
                                                               <v-btn 
  text :dark='!$route.name.includes("confinementservice")' @click='()=>{$router.push("/confinementservice")}'
>{{$t('confinementNanny')}}</v-btn>
                          
                            
                        </li>


                        <li class="nav-item">
                                                               <v-btn 
  text :dark='!$route.name.includes("relatedVideo")' @click='()=>{$router.push("/relatedVideo")}'
>{{$t('relatedVideo')}}</v-btn>
                          
                            
                        </li>


                        <li class="nav-item">
                                                                   <v-btn
  text :dark='!$route.name.includes("contact")' @click='()=>{$router.push("/contact")}'
>{{$t('ContactUs')}}</v-btn>
                          
                          
                        </li>
                        <li class="nav-item" >
                            <div class="video-promo-content">
                               
                        <a @click='()=>{$router.push("/matching")}'
                            style='color:#fe8a64; background:white; line-height : 33px ; width : 65px; height : 35px ;border-radius : 0%' class="video-play-icon text-center"
                            >{{$t('Matching')}}
                        </a>
                    </div>

                                     
                           
                        </li>
                       
                    </ul>
                    <div class='ml-4'>
|
                      <a style='cursor: pointer;color: white;'  @click="changeLocale('zh-HK')">繁</a>
                      |
            <a style='cursor: pointer;color: white' @click="changeLocale('en')">Eng</a>
   |
                    </div>
                </div>
                
            </div>
        </nav>
        <!--end navbar-->
    </header>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
export default {
    props: {
        coloredLogo: {
            type: Boolean,
            default: false,
        },
    },
    data: function () {
        return {
            windowTop: 0,
            collapsed: true,
        };
    },
    mounted() {
        window.addEventListener("scroll", this.onScroll);
    },
    beforeDestroy() {
        window.removeEventListener("scroll", this.onScroll);
    },
    methods: {
         changeLocale(locale) {
           
        this.language = locale;
    },
        onScroll: function () {
            this.windowTop = window.top.scrollY;
        },
        mobileNavClicked: function () {
            this.collapsed = !this.collapsed;
        },
    },
    computed: {
           ...mapFields([
      'language'
    ]),
        hasAffix: function () {
            return this.windowTop > 0;
        },
    },
};
</script>
<style scoped>
.bold{
    font-weight : bold;
}
.matchBtn{

  color: rgb(225, 248, 12);
    background-color: #199319;
  color: white;
  text-decoration: none;
 
}
</style>

