<template>
    <section style="background-color: #d5d5d521;" class="promo-section ptb-100">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-7 col-md-8">
                    <div class="section-heading text-center mb-5">
                        <h2>
                            {{$t('Why')}}
                        </h2>
                        <p class="lead">
                            {{$t('WhyReason')}}
                        </p>
                    </div>
                </div>
            </div>
            <div class="row equal">
                <div class="col-md-4 col-lg-4">
                    <div
                        class="single-promo single-promo-hover single-promo-1 rounded text-center white-bg p-5 h-100"
                    >
                        <div class="circle-icon mb-5">
                            <span class="ti-vector text-white"></span>
                        </div>
                        <h5> {{$t('AI')}}</h5>
                        <p>
                             {{$t('AIReason')}}
                        </p>
                        <br />
                         <router-link to="/about"
                                class="btn outline-btn  mt-5"
                                > {{$t('AboutUs')}}</router-link
                            >
                    </div>
                </div>
                <div class="col-md-4 col-lg-4">
                    <div
                        class="single-promo single-promo-hover single-promo-1 rounded text-center white-bg p-5 h-100"
                    >
                        <div class="circle-icon mb-4">
                            <span class="ti-id-badge text-white"></span>
                        </div>
                        <h5>{{$t('domesticHelper')}}</h5>
                        <p>
                          {{$t('domesticHelperReason')}}
                        </p>
                        <br />
                        <router-link
                                to="/services"
                                class="btn outline-btn mb-3 mt-5"
                                >{{$t('OurServices')}}</router-link
                            >
                    </div>
                </div>
                <div class="col-md-4 col-lg-4">
                    <div
                        class="single-promo single-promo-hover single-promo-1 rounded text-center white-bg p-5 h-100"
                    >
                        <div class="circle-icon mb-5">
                            <span class="ti-check text-white"></span>
                        </div>
                        <h5>{{$t('bestfit')}}</h5>
                        <p>
                            {{$t('bestfitReason')}}
                        </p>
                        <br />
                        <router-link
                                to="/matching"
                                class="btn outline-btn mb-3 mt-5"
                                >{{$t('MatchNow')}}</router-link
                            >
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    name: "Promo",
};
</script>