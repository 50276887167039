<template>
    <div>
        <nav-bar />
        <div class="main">
            
            <section >
            <v-carousel height="605" v-model="model"  :show-arrows="false">
    <v-carousel-item>
        
                <banner />
           
    </v-carousel-item>
    <v-carousel-item>
        <testimonial   :feedbacks="feedbacks1"/>
        </v-carousel-item>  
        <v-carousel-item>
            <testimonial   :feedbacks="feedbacks2"/>
        </v-carousel-item>  
  </v-carousel>
</section>

            <Promo />
            
            <!-- <features :show-badge="false" :is-gray="true" /> -->
             <About :is-gray="false" />
            <pricing :is-gray="false"/>
           
            <site-footer />
        </div>
    </div>
</template>

<script>
import About from "../../views/index-five/About";
import Promo from "../../views/index-one/Promo.vue"
import NavBar from "../../views/commons/NavBar";
import Banner from "../../views/index-five/Banner";
import Pricing from "../../views/index-five/Pricing";
import SiteFooter from "../../views/index-five/SiteFooter";
import testimonial from "../../views/testimonial"


export default {
    name: "IndexFive",
    data: () => ({
      model: 0,
      feedbacks1 : [
               {
                content : `"本身好擔心趕唔切生bb前工人到埗
第一次請工人又咩都唔識
多謝Anthony不厭其煩解答好多問題
同星空中心既職員們 跟得好足悉心跟進下
最後工人都比bb出世前到埗😊
除此之外 工人質素好好 好聽話又純樸
中心既跟進服務都好好 回覆好快好妥當 
Highly recommend 左好多朋友請工人搵依家🥰"`,
                name : "Yuki",
               },
               {
                content : `當時係朋友介紹我搵anthony ，
因為之前請過2次工人都有不愉快經歷。自己家中有個小朋友，又快有另一個bb出世，所以急切想在預產期前有工人到家中幫手。開始時，我同anthony 講出我請工人要求和過往不愉快經歷，他很細心聆聽，了解需求和問題，然後就答應會幫我盡快處理問題。當時記得是撞正聖誕節....`,
name : "Mrs Chiu"
               },
               {
                content : "最好嘅地方系貴公司有比較多外傭给我去選擇, 並且清楚知道客人的要求而且十分有效地幫我去篩選合適的外傭而给我挑選, 解决我事前恐怕找不到好外傭的煩惱, 謝謝幫忙🙏",
                name : "P小姐"
               },
              
            ],
        feedbacks2  : [
        {
                content : `第一次請姐姐，由於上網睇得太多失敗例子，本身都無期望可以一次就OK~
Anthony了解過我需要，介紹合適既姐姐俾我。最後真係人地口中所講中六合彩一樣，請到一個好姐姐照顧BB~竟然一次就搞掂🤝
感謝你地用心既幫忙，我仲即刻推薦埋俾朋友幫手搵姐姐❤️‍🔥`,
                name : "Vivian"
               },

               {
                content : `首先agent回覆快 有禮貌 有交帶 有問有答 積極提供不同姐姐選擇 比原定時間早左安排姐姐到港 😃
新請姐姐雖然未黎過香港 年紀輕 但表現勤力 唔會偷懶 感覺純良 有笑容 有禮貌 有耐性同小朋友玩 小朋友好快肯跟佢 煮飯有天份 家務都好好 希望佢keep住 廣東話再進步😃`,
                name : "楊小姐"
               }
        ],
    }),
    components: {
        Promo,
        About,
        NavBar,
        Banner,
        Pricing,
        SiteFooter,
        testimonial,
   
    },
};
</script>