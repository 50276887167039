<template>
    <section
        class="hero-section ptb-100 background-img"
        style="
            background: url('img/hero.jpg') no-repeat center center / cover;
        "
    >
        <div class="container">
            <div class="row align-items-center justify-content-center">
                <div class="col-md-9 col-lg-7">
                    <div
                        class="page-header-content text-white text-center pt-sm-5 pt-md-5 pt-lg-0"
                    >
                        <h1 class="text-white mb-0">{{$t('Matching')}}</h1>
                        <div class="custom-breadcrumb">
                            <ol
                                class="breadcrumb d-inline-block bg-transparent list-inline py-0"
                            >
                                <li class="list-inline-item breadcrumb-item">
                                    <router-link to="/">{{$t('home')}}</router-link>
                                </li>
                                <li
                                    class="list-inline-item breadcrumb-item active"
                                >
                                    {{$t('Matching')}}
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "Banner",
};
</script>

<style>
</style>