<template>
    <section
        id="blog"
        class="our-blog-section ptb-100"
    >
        <div class="container">
            <div class="row">
                <div class="col-md-8">
                    <div class="section-heading mb-5">
                        <h2>
                            {{ $t('OurServices') }}
                        </h2>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-4">
                                <div
                        class="single-blog-card card border-0 shadow-sm"
                    >
                        <img
                            src="img/sign.png"
                            class="card-img-top position-relative "
                            alt="blog"
                        />
                        <div class="meta-date card-body" style='height:250px'>

                            <h3 class="h5 card-title">
                                <a>{{ $t('Contract') }}</a>
                            </h3>
                            <p class="card-text">
                                {{ $t('ContractDesc') }}
                            </p>
                            <router-link to="/services" class="detail-link" style=' position: absolute; bottom : 5%'>
                                {{$t('getStarted')}}
                                <span class="ti-arrow-right"></span>
                            </router-link>
                        </div>
                    </div>
                </div>
                             <div class="col-md-4">
                                <div
                        class="single-blog-card card border-0 shadow-sm"
                    >
                        <img
                            src="img/oversea.png"
                            class="card-img-top position-relative "
                            alt="blog"
                        />
                        <div class="meta-date card-body" style='height:250px'>

                            <h3 class="h5 card-title">
                                <a>{{ $t('Oversea') }}</a>
                            </h3>
                            <p class="card-text">
                                {{ $t('OverseaDesc') }}
                            </p>
                            <router-link to="/services" class="detail-link" style=' position: absolute; bottom : 5%'>
                                {{$t('getStarted')}}
                                <span class="ti-arrow-right"></span>
                            </router-link>
                        </div>
                    </div>
                </div>
                                <div class="col-md-4">
                                <div
                        class="single-blog-card card border-0 shadow-sm"
                    >
                        <img
                            src="img/local.png"
                            class="card-img-top position-relative "
                            alt="blog"
                        />
                        <div class="meta-date card-body" style='height:250px'>

                            <h3 class="h5 card-title">
                                <a>{{ $t('Local') }}</a>
                            </h3>
                            <p class="card-text">
                                {{ $t('LocalDesc') }}
                            </p>
                            <router-link to="/services" class="detail-link" style=' position: absolute; bottom : 5%'>
                                {{$t('getStarted')}}
                                <span class="ti-arrow-right"></span>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "Pricing",
    props: {
        isGray: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style>
</style>