<template>
  <section
    id="services"
    class="package-section ptb-100"
  >
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-8">
          <div class="section-heading text-center mb-5">
            <h2>
              {{$t('OurServices')}}<br />
            </h2>

          </div>
        </div>
      </div>
      <div class="row justify-content-center">

        <div class="col-lg-4 col-md">

          <div class="card text-center single-pricing-pack">
            <div class="pt-4 pb-4">
              <h5>{{$t('Contract')}}
              </h5>
              <p><br />{{$t('ContractDesc')}}
              </p>
            </div>
            <img
              src="img/sign.png"
              class="card-img-top mt-3"
              alt="blog"
            />
            <div class="card-header py-4 border-0 pricing-header">
              <div class="h1 text-center mb-0">
                <p style='font-size : 14px'>{{$t('allinone')}}</p>
                $<span class="price font-weight-bolder">2,988+</span>
              </div>
            </div>
            <div class="card-body">
              <ul
                style='text-align: left;list-style-type: disc'
                class="list-unstyled text-sm mb-4 pricing-feature-list"
              >
                <li>{{$t('ContractDesc1')}}</li>
                <li>{{$t('ContractDesc2')}}</li>
                <li>{{$t('ContractDesc3')}}</li>
              </ul>
              <router-link
                to="/contact"
                class="btn outline-btn mb-3"
              >{{$t('ContactUs')}}</router-link>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md">
          <div class="card text-center single-pricing-pack">
            <div class="pt-4 pb-3">
              <h5>{{$t('Oversea')}}
              </h5>
              <p class='pl-3 pr-2'>{{$t('OverseaDesc')}}

              </p>
            </div>
            <img
              src="img/oversea.png"
              class="card-img-top mt-5"
              alt="blog"
            />
            <div class="card-header py-4 border-0 pricing-header">

              <div class="h1 text-center mb-0">
                <p style='font-size : 14px'>{{$t('allinone')}}</p>
                $<span class="price font-weight-bolder">16,800+</span>
              </div>
            </div>
            <div class="card-body">
              <ul
                style='text-align: left;list-style-type: disc'
                class="list-unstyled text-sm mb-4 pricing-feature-list"
              >
                <li>{{$t('OverSeaDetailDesc1')}}</li>
                <li>{{$t('OverSeaDetailDesc2')}}</li>
                <li>{{$t('OverSeaDetailDesc3')}}</li>
                <li>{{$t('OverSeaDetailDesc4')}}</li>
                <li>{{$t('OverSeaDetailDesc5')}}</li>
                <li>{{$t('OverSeaDetailDesc6')}}</li>
                <li>{{$t('OverSeaDetailDesc7')}}</li>
              </ul>
              <router-link
                to="/matching"
                class="btn outline-btn mb-3"
              >{{$t('MatchNow')}}</router-link>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md">
          <div class="card text-center single-pricing-pack">
            <div class="pt-4 pb-4">
              <h5>{{$t('Local')}}
              </h5>
              <p class='pl-3 pr-2'>{{$t('LocalDesc')}}</p>
            </div>
            <img
              src="img/local.png"
              class="card-img-top mt-2"
              alt="blog"
            />
            <div class="card-header py-4 border-0 pricing-header">
              <div class="h1 text-center mb-0">
                <p style='font-size : 14px'>{{$t('allinone')}}</p>
                $<span class="price font-weight-bolder">16,800+</span>
              </div>
            </div>
            <div class="card-body">
              <ul
                style='text-align: left;list-style-type: disc'
                class="list-unstyled text-sm mb-4 pricing-feature-list"
              >
                <li>{{$t('localDetailDesc1')}}</li>
                <li>{{$t('localDetailDesc2')}}</li>
                <li>{{$t('localDetailDesc3')}}</li>
              </ul>
              <router-link
                to="/matching"
                class="btn outline-btn mb-3"
              >{{$t('MatchNow')}}</router-link>
            </div>
          </div>
        </div>
      </div>

    </div>
  </section>
</template>

<script>
export default {
  name: "FaqTwo",
};
</script>

<style>
</style>